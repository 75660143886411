import t from '@jetshop/intl'
import React, { useState, useEffect } from 'react'
import { styled } from 'linaria/react'
import { theme } from './Theme'
import { pxToRem } from '../utils/css'
import { Link } from 'react-router-dom'
import MaxWidth from './Layout/MaxWidth'
import HelloRetailCarousel from './HelloRetail/Carousel'
import { useSiteSettingsQuery } from '../hooks/useSiteSettings'
import { HELLO_RETAIL_SETTINGS } from './CustomElements/elementTypes'
import { getHelloRetailProducts, HelloRetailHelper } from './HelloRetail/HelloRetailHelper'
import NotFoundImage from '../svg/verktygsboden-404.webp'
import { UseHelloRetail } from './HelloRetail/UseHelloRetail'
import { HelloRetailToJetshopCarousel } from './HelloRetail/HelloRetailToJetshop'

const ErrorContainer = styled.div`
   position: relative;
   height: 100%;
   margin: 0 auto;
`

const HeroTextContainer = styled.div`
    height: 100%;
    width: 100%;
    background: url(${NotFoundImage}) center;
    background-size: cover;
    position: relative;

    .notfound-title-text {
      color: white;
    }

    .overlay {
      top: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.85);
    }

    span {
      font-size: 64px;
      font-weight: bold;
    }

    // Override child-comp style
    .carousel-title {
      color: white;
    }
`

const NotFoundPage = () => {
   const [getSetting, loading] = useSiteSettingsQuery()
   const setting = getSetting(null, HELLO_RETAIL_SETTINGS)

   const RECOMMENDED_FOR_YOU = HelloRetailHelper('RECOMMENDED_FOR_YOU', setting)
   const POPULAR_PRODUCTS = HelloRetailHelper('POPULAR_PRODUCTS', setting)
   const LATEST_VISITED = HelloRetailHelper('LATEST_VISITED', setting)

   const { helloRetailRecoms, helloRetailLoading } = UseHelloRetail([
      RECOMMENDED_FOR_YOU,
      POPULAR_PRODUCTS,
      LATEST_VISITED,
   ])

   return (
      <>
         <HeroTextContainer className="container-fluid py-128 px-0">
            <div className="z-1 m-0"></div>
            <div className="overlay"></div>
            <div className="z-3 position-relative">
               <div className="px-128">
                  <span className='notfound-title-text'>
                     {t('NotFoundPage-errorText')}
                  </span>
               </div>
               <div className="py-64">
                  <HelloRetailToJetshopCarousel
                     className="py-32"
                     products={getHelloRetailProducts(
                        helloRetailRecoms,
                        POPULAR_PRODUCTS
                     )}
                     loading={helloRetailLoading}
                     title={POPULAR_PRODUCTS?.title}
                     render={modifiedResults => <HelloRetailCarousel {...modifiedResults}/>}
                  />
                  {/* <HelloRetailCarousel
                     className="py-32"
                     products={getHelloRetailProducts(
                        helloRetailRecoms,
                        POPULAR_PRODUCTS
                     )}
                     loading={helloRetailLoading}
                     title={POPULAR_PRODUCTS?.title}
                  ></HelloRetailCarousel>  */}
               </div>
            </div>
         </HeroTextContainer>
      </>
   )
}

export default NotFoundPage